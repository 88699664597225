import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import { createAnnoStore } from "../../util/state";
export const NameInput = observer(() => {
  const { setName, nextStage, popStage, url, name } = createAnnoStore;
  useEffect(() => {
    const asyncFunc = async () => {
      if (url) {
        const resp = await fetch(`/api/og/?url=${url}`);
        const json = await resp.json();
        const data = json.data;
        if (data.title && !name)
          setName(data.title);
      }
    };
    asyncFunc();
  }, [setName, url]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("button", {
    type: "reset",
    className: "p-2 hover:bg-white hover:bg-opacity-10 rounded focus:outline-none absolute top-0",
    onClick: () => {
      popStage();
    }
  }, /* @__PURE__ */ React.createElement(XIcon, {
    className: "w-4 h-4 text-white"
  })), /* @__PURE__ */ React.createElement("form", {
    onSubmit: (e) => {
      e.preventDefault();
      nextStage();
    },
    className: "flex flex-col"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "my-auto"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "text",
    value: name,
    onChange: (e) => setName(e.target.value),
    className: "border-indigo-500 focus:border-indigo-400 p-2 max-w-3xl bg-transparent border-b-2 focus:outline-none outline-none text-white block w-full sm:text-base transition",
    placeholder: (() => {
      const date = new Date();
      return `Untitled Project - ${date.toDateString()}`;
    })()
  }), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "bg-gray-300 bg-opacity-20 hover:bg-opacity-30 py-2.5 px-3 w-full rounded-md text-center text-white text-opacity-90 mt-3 group transition focus:outline-none"
  }, "Choose a name"))));
});
