var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
const social = [
  {
    name: "Twitter",
    href: "https://twitter.com/modfydotvideo",
    icon: (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
      fill: "currentColor",
      viewBox: "0 0 24 24"
    }, props), /* @__PURE__ */ React.createElement("path", {
      d: "M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
    }))
  },
  {
    name: "GitHub",
    href: "https://github.com/modfy",
    icon: (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
      fill: "currentColor",
      viewBox: "0 0 24 24"
    }, props), /* @__PURE__ */ React.createElement("path", {
      fillRule: "evenodd",
      d: "M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z",
      clipRule: "evenodd"
    }))
  }
];
export const Footer = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "py-4"
  }), /* @__PURE__ */ React.createElement("footer", {
    className: "z-10 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "border-t border-gray-200 border-opacity-10 py-12 text-center md:flex md:justify-between"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-base text-gray-400"
  }, "\xA9 Modfy, Inc. All rights reserved."), /* @__PURE__ */ React.createElement("div", {
    className: "mt-6 flex justify-center space-x-8 md:mt-0"
  }, social.map((item, itemIdx) => /* @__PURE__ */ React.createElement("a", {
    key: itemIdx,
    href: item.href,
    className: "inline-flex text-gray-400 hover:text-gray-500"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "sr-only"
  }, item.name), /* @__PURE__ */ React.createElement(item.icon, {
    className: "h-6 w-6",
    "aria-hidden": "true"
  })))))));
};
export const ModfyBanner = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "py-4 sm:py-10"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex w-full justify-center"
  }, /* @__PURE__ */ React.createElement("div", {
    style: {
      backgroundImage: "url(/images/Bg.png)",
      backgroundRepeat: "no-repeat"
    },
    className: "max-w-7xl rounded-lg flex-1 mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "grid w-full"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "stackedLayer w-full flex items-center justify-start"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "lg:w-0 lg:flex-1 sm:flex sm:flex-col sm:space-y-4"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: "text-2xl font-extrabold tracking-tight text-white sm:text-4xl",
    id: "newsletter-headline"
  }, "Turn your ideas into reality"), /* @__PURE__ */ React.createElement("p", {
    className: "mt-3 max-w-md text-md pb-2 leading-6 text-gray-300"
  }, "Like anno? But want something more? Sign up for early access to our full video editor."), /* @__PURE__ */ React.createElement("div", {
    className: "sm:flex py-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "mt-3 rounded-md shadow sm:mt-0 sm:flex-shrink-0"
  }, /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: "https://modfy.video/discord",
    className: "w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500",
    rel: "noreferrer noopener"
  }, "Request early access"))))), /* @__PURE__ */ React.createElement("div", {
    className: "stackedLayer hidden w-full pointer-events-none lg:flex items-start justify-end"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "max-w-2xl -mt-8 -mr-8"
  }, /* @__PURE__ */ React.createElement("img", {
    alt: "Timeline Popup",
    className: "filter drop-shadow-2xl",
    src: "/images/Timeline.png"
  })))))));
};
