import { button, folder, useControls } from 'leva'
import prettyBytes from 'pretty-bytes'
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import toast from 'react-hot-toast'

import { ANNO_USER_ID, MediaFileDetails } from '@editor/interfaces'
import { isAnno } from '@editor/utils'

import { useReactiveControls } from './adminStore'

export const useAnnoAdminPanel = () => {
  const [_, __, removeCookie] = useCookies(['token', ANNO_USER_ID])

  // default controls
  const [_v, set] = useControls(() => ({
    Auth: folder({
      email: {
        value: '',
        editable: false
      },
      token: {
        value: 'false',
        editable: false
      },
      annoUserId: {
        value: 'false',
        editable: false
      },
      logout: button(() => {
        if (isAnno) {
          removeCookie('annoUserId')
          removeCookie('token')
        } else {
          // Log out for editor
        }
        toast.success('Logged out')
      })
    })
  }))

  useControls('Misc', {
    isAnno: {
      value: 'true',
      editable: false
    }
  })

  return { set }
}

export const useAnnoProjectLeva = ({
  collaborators,
  mediaId
}: {
  collaborators: string[]
  mediaId: string
}) => {
  const [mediaFileDetails, setMediaFileDetails] = useState<
    MediaFileDetails | undefined
  >(undefined)

  useEffect(() => {
    const getMediaFileDetails = async (mediaId: string) => {
      const res = await fetch(
        `/api/admin/getMediaFileDetails?id=${mediaId}`
      ).then((res) => res.json())
      setMediaFileDetails(res)
    }

    if (mediaId) getMediaFileDetails(mediaId)
  }, [mediaId])

  useReactiveControls('AnnoProject.collaborators', {
    label: 'Collaborators',
    rows: true,
    value: `Total: ${collaborators.length}\n` + collaborators.join('\n'),
    editable: false
  })
  useReactiveControls('AnnoProject.mediaId', {
    label: 'Media id',
    value: mediaId,
    editable: false
  })
  useReactiveControls('AnnoProject.fileExists', {
    label: 'File Exists',
    value: String(mediaFileDetails?.exists),
    editable: false
  })
  useReactiveControls('AnnoProject.fileSize', {
    label: 'File Size',
    value: mediaFileDetails?.size
      ? prettyBytes(mediaFileDetails.size)
      : 'undefined',
    editable: false
  })
  useReactiveControls('AnnoProject.uploadInProgress', {
    label: 'Upload in progress',
    value: String(mediaFileDetails?.uploadInProgress),
    editable: false
  })
}
