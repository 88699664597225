import React, { useState } from "react";
export const Img = ({
  src,
  className,
  alt
}) => {
  const [blur, setBlur] = useState(true);
  const [shouldFallback, setShouldFallback] = useState(false);
  if (shouldFallback)
    return /* @__PURE__ */ React.createElement("div", {
      className: "w-full border-[1px] hover:bg-opacity-20 border-blueGray-100 border-opacity-10 h-40 bg-purple-400 rounded-lg bg-opacity-10"
    });
  return /* @__PURE__ */ React.createElement("img", {
    src,
    className: blur ? "w-72 h-40 bg-purple-400 rounded-lg animate-pulse bg-opacity-10" : className,
    alt: shouldFallback ? "" : alt || "Blurred img",
    onLoad: () => setBlur(false),
    onError: () => setShouldFallback(true)
  });
};
