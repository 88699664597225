var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { observer } from "mobx-react";
import React from "react";
import LoadingIcon from "@editor/components/icons/LoadingIcon";
import { AnnoCreationStage } from "@editor/interfaces";
import { classNames } from "@editor/utils/classnames";
import { createAnnoStore } from "../../util/state";
import Onboard from "../Onboard/Onboard";
import { InputEmbed } from "./InputEmbed";
import { NameInput } from "./NameInput";
import {
  AnnotateHomeSelection
} from "./Selection";
import { useAnnotateSelection } from "./useAnnoSelection";
const stageMap = {
  [AnnoCreationStage.Selection]: (props) => /* @__PURE__ */ React.createElement(AnnotateHomeSelection, __spreadValues({}, props)),
  [AnnoCreationStage.Embed]: (props) => /* @__PURE__ */ React.createElement(InputEmbed, __spreadValues({}, props)),
  [AnnoCreationStage.NameProject]: () => /* @__PURE__ */ React.createElement(NameInput, null),
  [AnnoCreationStage.Onboard]: () => /* @__PURE__ */ React.createElement("div", {
    className: "my-auto px-3"
  }, /* @__PURE__ */ React.createElement(Onboard, {
    onCreated: createAnnoStore.nextStage
  })),
  [AnnoCreationStage.Loading]: () => /* @__PURE__ */ React.createElement("div", {
    className: "text-white m-auto flex items-center"
  }, /* @__PURE__ */ React.createElement(LoadingIcon, {
    className: "w-5 h-5 mr-2 animate-spin"
  }), /* @__PURE__ */ React.createElement("span", null, "Creating your anno"))
};
const stages = Object.keys(stageMap);
const CreateAnno = observer((chooseSelectors) => {
  const { onFileInput, handleAddEmbed } = useAnnotateSelection();
  const props = __spreadValues({
    onFileInput,
    handleAddEmbed
  }, chooseSelectors);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, stages.map((stage) => {
    var _a;
    return /* @__PURE__ */ React.createElement("div", {
      key: stage,
      className: classNames("stackedLayer transition-all flex flex-col justify-center", stage === ((_a = createAnnoStore.stages[createAnnoStore.currentStage]) == null ? void 0 : _a.toString()) ? "translate-x-0 opacity-100" : "translate-x-1/3 opacity-0 pointer-events-none")
    }, stageMap[stage](props));
  }));
});
export default CreateAnno;
