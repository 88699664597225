var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  useGetAnnoProjectsFromUserQuery
} from "@graphql/schema/client";
import { useAnnotateUser } from "../hooks/useAnnotateUser";
export const useAnnoDashboard = (_userId, search) => {
  var _a;
  const { user } = useAnnotateUser();
  const { data, loading } = useGetAnnoProjectsFromUserQuery({
    skip: !user,
    onError: (err) => {
      console.error(err);
      toast.error("Failed to fetch projects");
    }
  });
  const projects = ((_a = data == null ? void 0 : data.getAnnotateUser) == null ? void 0 : _a.invitedProjects) || [];
  const extendedProjects = projects.map((p) => {
    return __spreadProps(__spreadValues({}, p), { thumbnail: `/api/cdn/thumbnail/${p.id}` });
  });
  extendedProjects.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  const [fuse, setFuse] = useState();
  useEffect(() => {
    if (fuse)
      return;
    const fuseOptions = {
      keys: ["name"]
    };
    const setupFuse = async () => {
      const Fuse = (await import("fuse.js")).default;
      const newFuse = new Fuse([], fuseOptions);
      setFuse(newFuse);
    };
    setupFuse();
  }, [search]);
  const miniDashboardProjects = extendedProjects.splice(0, 2);
  fuse == null ? void 0 : fuse.setCollection(extendedProjects);
  const dashboardProjects = search && fuse ? fuse.search(search).map((elm) => elm.item) : extendedProjects;
  return { loading, projects: dashboardProjects, miniDashboardProjects };
};
