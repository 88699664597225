import React, { Fragment, useRef } from "react";
import toast from "react-hot-toast";
import LoadingIcon from "@editor/components/icons/LoadingIcon";
import {
  GetAnnoProjectsFromUserDocument,
  useDeleteAnnotateProjectMutation
} from "@graphql/schema/client";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
const DeleteProjectModal = ({
  open,
  projectId,
  projectName,
  onClose
}) => {
  const cancelButtonRef = useRef(null);
  const [deleteProject, { loading }] = useDeleteAnnotateProjectMutation({
    variables: { id: projectId },
    onCompleted: () => {
      toast.success("Deleted project");
    },
    refetchQueries: [GetAnnoProjectsFromUserDocument]
  });
  return /* @__PURE__ */ React.createElement(Transition.Root, {
    show: open,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    className: "relative z-10",
    initialFocus: cancelButtonRef,
    onClose
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-0 z-10 overflow-y-auto"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
    enterTo: "opacity-100 translate-y-0 sm:scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
    leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  }, /* @__PURE__ */ React.createElement(Dialog.Panel, {
    className: "relative transform overflow-hidden rounded-lg bg-blueGray-900 text-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "sm:flex sm:items-start"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
  }, /* @__PURE__ */ React.createElement(ExclamationIcon, {
    className: "h-6 w-6 text-red-600",
    "aria-hidden": "true"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    as: "h3",
    className: "text-lg font-medium leading-6 text-gray-100"
  }, "Delete ", `"${projectName}"`, "?"), /* @__PURE__ */ React.createElement("div", {
    className: "mt-2"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-gray-500"
  }, "Are you sure you want to delete this project?")))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    disabled: loading,
    className: "inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm",
    onClick: () => deleteProject()
  }, loading ? /* @__PURE__ */ React.createElement(LoadingIcon, {
    className: "w-4 h-4 animate-spin"
  }) : "Delete"), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm",
    onClick: onClose,
    ref: cancelButtonRef
  }, "Cancel"))))))));
};
export default DeleteProjectModal;
