import React from "react";
import LoadingIcon from "@editor/components/icons/LoadingIcon";
import { DashboardItem } from "./DashboardItem";
import { useAnnoDashboard } from "./useAnnoDashboard";
export const MiniDashboard = ({ userId }) => {
  const { miniDashboardProjects, loading } = useAnnoDashboard(userId);
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col py-8 items-start w-full"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex w-full py-4 justify-start"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-3xl sm:text-lg text-gray-400"
  }, "Recent Annos")), loading ? /* @__PURE__ */ React.createElement("div", {
    className: "flex w-full justify-center items-center"
  }, /* @__PURE__ */ React.createElement(LoadingIcon, {
    className: "w-6 h-6 animate-spin"
  })) : /* @__PURE__ */ React.createElement("div", {
    className: "grid w-full grid-cols-2  max-w-5xl gap-8 sm:gap-2 place-items-stretch items-center  justify-items-stretch grid-flow-row auto-rows-max"
  }, miniDashboardProjects.map((project) => /* @__PURE__ */ React.createElement(DashboardItem, {
    project,
    key: project.id
  }))));
};
