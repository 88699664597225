import copy from "copy-to-clipboard";
import Link from "next/link";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@editor/components/Radix/Dropdown";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { Img } from "../Image";
import DeleteProjectModal from "./DeleteProjectModal";
import RenameProjectModal from "./RenameProjectModal";
export const DashboardItem = ({
  project
}) => {
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col space-y-2 items-center justify-end w-full h-full"
  }, /* @__PURE__ */ React.createElement(Link, {
    href: `/${project.id}`,
    passHref: true
  }, /* @__PURE__ */ React.createElement("a", {
    className: "w-full h-full flex items-center justify-end flex-col",
    href: `/${project.id}`
  }, /* @__PURE__ */ React.createElement(Img, {
    alt: "Thumbnail",
    className: "w-full h-full max-h-40 opacity-70 hover:opacity-90 object-cover border rounded-lg border-white border-opacity-10",
    src: project.thumbnail
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "flex space-x-20 items-center justify-between w-full"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "flex-1 text-base font-medium text-white text-opacity-30 truncate"
  }, project.name), /* @__PURE__ */ React.createElement(DropdownMenu, null, /* @__PURE__ */ React.createElement(DropdownMenuTrigger, {
    asChild: true
  }, /* @__PURE__ */ React.createElement(DotsVerticalIcon, {
    className: "h-6 w-6 text-white text-opacity-30"
  })), /* @__PURE__ */ React.createElement(DropdownMenuContent, {
    className: "bg-gray-50",
    sideOffset: 5
  }, /* @__PURE__ */ React.createElement(DropdownMenuItem, {
    onClick: () => setRenameModalOpen(true)
  }, "Rename"), /* @__PURE__ */ React.createElement(DropdownMenuItem, {
    onClick: () => {
      copy(`${window.location.origin}/${project.id}`);
      toast.success("Copied to clipboard");
    }
  }, "Share")))), /* @__PURE__ */ React.createElement(RenameProjectModal, {
    open: renameModalOpen,
    projectId: project.id,
    projectName: project.name,
    onClose: () => setRenameModalOpen(false)
  }), /* @__PURE__ */ React.createElement(DeleteProjectModal, {
    open: deleteModalOpen,
    projectId: project.id,
    projectName: project.name,
    onClose: () => setDeleteModalOpen(false)
  }));
};
