import { observer } from "mobx-react";
import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { createAnnoStore } from "../../util/state";
import { selections } from "./Selection";
export const InputEmbed = observer(({ handleAddEmbed }) => {
  const { inputSelectionType, url, setUrl, popStage } = createAnnoStore;
  const selectedItem = inputSelectionType ? selections[inputSelectionType] : null;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("button", {
    type: "reset",
    className: "p-2 hover:bg-white hover:bg-opacity-10 rounded focus:outline-none absolute top-0",
    onClick: () => {
      popStage();
    }
  }, /* @__PURE__ */ React.createElement(XIcon, {
    className: "w-4 h-4 text-white"
  })), /* @__PURE__ */ React.createElement("form", {
    onSubmit: handleAddEmbed,
    className: "flex flex-col"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "my-auto"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "url",
    value: url,
    onChange: (e) => setUrl(e.target.value),
    className: "border-indigo-500 focus:border-indigo-400 p-2 max-w-3xl bg-transparent border-b-2 focus:outline-none outline-none text-white block w-full sm:text-base transition",
    placeholder: selectedItem == null ? void 0 : selectedItem.placeholder,
    required: true
  }), /* @__PURE__ */ React.createElement("button", {
    type: "submit",
    className: "bg-gray-300 bg-opacity-20 hover:bg-opacity-30 py-2.5 px-3 w-full rounded-md text-center text-white text-opacity-90 mt-3 group transition focus:outline-none"
  }, "Continue"))));
});
