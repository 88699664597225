import { button, folder, useControls } from 'leva'
import { Schema } from 'leva/src/types'

export { button, folder }

export const useAdminPanel = <S extends Schema>(schema: S) => {
  const [adminValues, setAdminValues] = useControls<S, () => S, () => S>(
    () => schema
  )

  return { adminValues, setAdminValues }
}
