import { observer } from "mobx-react";
import React, { Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import LoadingIcon from "@editor/components/icons/LoadingIcon";
import {
  GetAnnoProjectsFromUserDocument,
  useUpdateAnnotateProjectMutation
} from "@graphql/schema/client";
import { Dialog, Transition } from "@headlessui/react";
const RenameProjectModal = ({
  open,
  projectId,
  projectName,
  onClose
}) => {
  const cancelButtonRef = useRef(null);
  const [newProjectName, setNewProjectName] = useState(projectName);
  const [updateProject, { loading }] = useUpdateAnnotateProjectMutation({
    variables: {
      input: { projectId, name: newProjectName }
    },
    onCompleted: () => {
      toast.success("Project name updated");
      onClose();
    },
    refetchQueries: [GetAnnoProjectsFromUserDocument]
  });
  return /* @__PURE__ */ React.createElement(Transition.Root, {
    show: open,
    as: Fragment
  }, /* @__PURE__ */ React.createElement(Dialog, {
    as: "div",
    className: "relative z-10",
    initialFocus: cancelButtonRef,
    onClose
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0",
    enterTo: "opacity-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100",
    leaveTo: "opacity-0"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "fixed inset-0 z-10 overflow-y-auto"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
  }, /* @__PURE__ */ React.createElement(Transition.Child, {
    as: Fragment,
    enter: "ease-out duration-300",
    enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
    enterTo: "opacity-100 translate-y-0 sm:scale-100",
    leave: "ease-in duration-200",
    leaveFrom: "opacity-100 translate-y-0 sm:scale-100",
    leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  }, /* @__PURE__ */ React.createElement(Dialog.Panel, {
    className: "relative transform overflow-hidden rounded-lg bg-blueGray-900 text-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
  }, /* @__PURE__ */ React.createElement("form", {
    onSubmit: (e) => {
      e.preventDefault();
      updateProject();
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "text-center"
  }, /* @__PURE__ */ React.createElement(Dialog.Title, {
    as: "h3",
    className: "text-xl font-medium leading-6 text-gray-100"
  }, "Rename Project"), /* @__PURE__ */ React.createElement("div", {
    className: "w-full h-full flex-1 sm:max-w-md mt-4"
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: "name",
    className: "sr-only"
  }, "Project Name"), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    name: "name",
    className: "focus:ring-indigo-500 active:bg-transparent focus:outline-none bg-transparent focus:border-indigo-500 text-gray-50 block w-full sm:text-base py-2 border-indigo-500 border-b-2",
    placeholder: projectName,
    value: newProjectName,
    onChange: (e) => setNewProjectName(e.target.value)
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
  }, /* @__PURE__ */ React.createElement("button", {
    type: "button",
    disabled: loading || newProjectName === projectName || !newProjectName,
    className: "inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm disabled:opacity-75",
    onClick: () => updateProject()
  }, loading ? /* @__PURE__ */ React.createElement(LoadingIcon, {
    className: "text-white animate-spin h-4 w-4"
  }) : "Rename"), /* @__PURE__ */ React.createElement("button", {
    type: "button",
    className: "mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm",
    onClick: onClose,
    ref: cancelButtonRef
  }, "Cancel"))))))));
};
export default observer(RenameProjectModal);
