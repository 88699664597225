import { autorun } from "mobx";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useAnnoAdminPanel } from "@editor/admin/annoLeva";
import { button, folder, useAdminPanel } from "@editor/admin/useAdminPanel";
import { FileDragNDrop } from "@editor/files";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import {
  useAnnotateUser,
  useLegacyAnnotateUser
} from "../components/hooks/useAnnotateUser";
import { Footer, ModfyBanner } from "../components/Landing/Common";
import CreateAnno from "../components/Landing/CreateAnno";
import { Dashboard } from "../components/Landing/Dashboard";
import { MiniDashboard } from "../components/Landing/MiniDashboard";
import { handleFileInput } from "../util/selection";
import { createAnnoStore } from "../util/state";
const App = () => {
  var _a, _b;
  const router = useRouter();
  const { user, fingerprint, removeUserIdCookie, removeTokenCookie } = useAnnotateUser();
  useLegacyAnnotateUser();
  const userId = (_a = user == null ? void 0 : user.getAnnotateUser) == null ? void 0 : _a.id;
  const userName = (_b = user == null ? void 0 : user.getAnnotateUser) == null ? void 0 : _b.name;
  useEffect(() => {
    if (user == null ? void 0 : user.getAnnotateUser) {
      posthog.identify(user.getAnnotateUser.id, user.getAnnotateUser);
    }
  }, [userId]);
  useEffect(() => {
    const createAnnoReactionDisposer = autorun(() => {
      const _ = createAnnoStore.fileInput;
      const __ = createAnnoStore.currentStage;
      createAnnoStore.createAnnoProject(fingerprint, userId, userName, router);
    });
    return () => {
      createAnnoReactionDisposer();
    };
  }, [fingerprint, userId, userName, router]);
  useAnnoAdminPanel();
  useAdminPanel({
    Auth: folder({
      changeFingerprint: button(() => {
        const params = new URLSearchParams({
          userId: userId || "",
          fingerprint: fingerprint || ""
        });
        toast.promise(fetch(`/api/admin/scrambleFingerprint?${params.toString()}`).then(() => {
          removeUserIdCookie();
          removeTokenCookie();
        }), {
          error: "Failed to scramble fingerprint",
          success: "Fingerprint scrambled successfully",
          loading: "Scrambling fingerprint..."
        });
      })
    })
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(FileDragNDrop, {
    handleDrop: async (e) => {
      e.preventDefault();
      const dropped = e.dataTransfer.files.item(0);
      if (!dropped)
        return;
      handleFileInput(dropped);
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "grid bg-[#060414]"
  }, /* @__PURE__ */ React.createElement("main", {
    className: "z-10 h-full w-full mx-auto px-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex w-full justify-center items-center "
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex max-w-5xl w-full items-center sm:pt-20"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex-1 flex justify-start items-center"
  }, /* @__PURE__ */ React.createElement("img", {
    crossOrigin: "anonymous",
    className: "h-16 w-auto -ml-3",
    src: "https://editor.modfy.video/images/logo.svg",
    alt: "Modfy"
  })), /* @__PURE__ */ React.createElement("h1", {
    className: "text-3xl text-white font-eudoxus font-semibold"
  }, "anno.so"))), /* @__PURE__ */ React.createElement("div", {
    className: "max-w-7xl mx-auto flex justify-center items-start flex-col sm:flex-row py-6 sm:py-20"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col px-4 w-full sm:w-1/2"
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "text-3xl mb-8 md:text-6xl font-eudoxus font-extrabold text-gray-50 tracking-tight max-w-[18ch]"
  }, "Review videos and music on the fly."), /* @__PURE__ */ React.createElement("p", {
    className: "text-gray-400 text-base md:text-lg max-w-[42ch]"
  }, "The only tool you need to collaboratively annotate, comment and share your ideas."), userId ? /* @__PURE__ */ React.createElement("div", {
    className: "hidden sm:block w-full"
  }, /* @__PURE__ */ React.createElement(MiniDashboard, {
    userId
  })) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", {
    className: "text-gray-400 text-base md:text-lg max-w-[42ch] mt-4 flex items-center"
  }, /* @__PURE__ */ React.createElement("span", null, "Select a file and get started"), /* @__PURE__ */ React.createElement(ArrowCircleRightIcon, {
    className: "h-6 w-6 ml-2"
  })), /* @__PURE__ */ React.createElement("a", {
    className: "hidden sm:block mr-auto mt-8",
    href: "https://www.producthunt.com/posts/anno-by-modfy?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-anno-by-modfy",
    target: "_blank",
    rel: "noreferrer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/images/top-post-badge.svg",
    alt: "Anno by Modfy - Annotate, comment and share your videos easily | Product Hunt",
    crossOrigin: "anonymous",
    style: { width: 250, height: 54 },
    width: "250",
    height: "54"
  })))), /* @__PURE__ */ React.createElement("div", {
    className: "mx-auto rounded-xl border-dashed border border-gray-500 relative w-full p-3 mb-6 mt-16 sm:m-0 sm:w-1/2 max-w-[450px] sm:p-8"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute rounded-full w-[90%] h-[90%] -top-8 -right-6",
    style: {
      background: "rgba(124, 58, 237, 0.6)",
      filter: "blur(max(7vw, 90px))"
    }
  }), /* @__PURE__ */ React.createElement("div", {
    className: "absolute rounded-full w-[90%] h-[90%] -bottom-8 -left-6",
    style: {
      background: "rgba(88, 80, 236, 0.6)",
      filter: "blur(max(7vw, 90px))"
    }
  }), /* @__PURE__ */ React.createElement("div", {
    className: "grid border border-gray-600 rounded-lg p-3 overflow-hidden",
    style: {
      backgroundColor: "#D9D9D91A",
      boxShadow: "0px 4px 37px rgba(0, 0, 0, 0.25)",
      backdropFilter: "blur(20px)"
    }
  }, /* @__PURE__ */ React.createElement(CreateAnno, null)))), userId ? /* @__PURE__ */ React.createElement("div", {
    className: "sm:hidden w-full"
  }, /* @__PURE__ */ React.createElement(MiniDashboard, {
    userId
  })) : /* @__PURE__ */ React.createElement("a", {
    className: "sm:hidden mx-auto mb-12 flex justify-center",
    href: "https://www.producthunt.com/posts/anno-by-modfy?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-anno-by-modfy",
    target: "_blank",
    rel: "noreferrer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/images/top-post-badge.svg",
    alt: "Anno by Modfy - Annotate, comment and share your videos easily | Product Hunt",
    crossOrigin: "anonymous",
    style: { width: 250, height: 54 },
    width: "250",
    height: "54"
  })), /* @__PURE__ */ React.createElement("div", {
    className: "mb-12 w-full flex justify-center"
  }, userId ? /* @__PURE__ */ React.createElement(Dashboard, {
    userId
  }) : /* @__PURE__ */ React.createElement("img", {
    alt: "hero img",
    className: "max-w-5xl w-full h-auto",
    src: "/images/hero.png"
  })), /* @__PURE__ */ React.createElement(ModfyBanner, null)), /* @__PURE__ */ React.createElement(Footer, null))));
};
export default App;
