import React, { useState } from "react";
import LoadingIcon from "@editor/components/icons/LoadingIcon";
import { SearchIcon } from "@heroicons/react/outline";
import { DashboardItem } from "./DashboardItem";
import { useAnnoDashboard } from "./useAnnoDashboard";
export const Dashboard = ({ userId }) => {
  const [search, setSearch] = useState("");
  const { projects, loading } = useAnnoDashboard(userId, search);
  if (loading)
    return /* @__PURE__ */ React.createElement(LoadingIcon, {
      className: "w-8 h-8 text-gray-300 animate-spin"
    });
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex w-full filter justify-center items-center flex-col"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "grid w-full"
  }, /* @__PURE__ */ React.createElement("div", {
    style: {
      background: "rgba(124, 58, 237, 0.6)"
    },
    className: "stackedLayer blur-[200px] pointer-events-none filter rounded-[200px]"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "stackedLayer w-full flex  justify-center items-center flex-col"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "w-full flex justify-start max-w-5xl"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "text-lg leading-10 text-white text-opacity-30"
  }, "All Annos")), /* @__PURE__ */ React.createElement("div", {
    className: "max-w-5xl flex w-full justify-start items-center"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "inline-flex flex-col items-center justify-start px-4 py-1.5 h-12 rounded-lg ring-1 bg-gray-300 bg-opacity-5 ring-gray-200 ring-opacity-20"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "inline-flex h-full w-full items-center justify-start"
  }, /* @__PURE__ */ React.createElement("input", {
    className: "w-full flex-1 focus:ring-0 focus:outline-none min-w-[500px]  h-full text-lg placeholder-gray-200 placeholder-opacity-30 bg-transparent leading-10 text-white",
    placeholder: "Search",
    value: search,
    onChange: (e) => setSearch(e.target.value)
  }), /* @__PURE__ */ React.createElement(SearchIcon, {
    className: "w-4 h-4 text-gray-200"
  })))))), /* @__PURE__ */ React.createElement("div", {
    className: "grid w-full grid-cols-2 sm:grid-cols-3 max-w-5xl py-8 place-items-stretch items-center gap-12 justify-items-stretch grid-flow-row auto-rows-max"
  }, projects.length > 1 ? projects.map((project) => /* @__PURE__ */ React.createElement(DashboardItem, {
    key: project.id,
    project
  })) : /* @__PURE__ */ React.createElement("div", {
    className: "text-gray-300 text-base col-span-3 self-center"
  }, "No projects found")));
};
