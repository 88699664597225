import React from 'react'
import * as colors from 'tailwindcss/colors'
import { apply, css, keyframes, tw } from 'twind/css'

import { classNames } from '@editor/utils'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import {
  CheckIcon,
  ChevronRightIcon,
  DotFilledIcon,
  HamburgerMenuIcon
} from '@radix-ui/react-icons'

const styled = <T extends React.JSXElementConstructor<any>>(
  Element: T,
  className: string,
  style?: React.CSSProperties
) => {
  const StyledElement = (props: React.ComponentProps<T>) => {
    return (
      <Element
        {...(props as any)}
        className={classNames(props.className, className)}
        style={{ ...style, ...props.style }}></Element>
    )
  }

  return StyledElement
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' }
})

const ContentStyle = css({
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade }
    }
  }
})

const StyledContent = styled(
  DropdownMenuPrimitive.Content,
  `border-6 p-1 bg-blueGray-900 border-gray-50 min-w-[220px] rounded-md shadow-2xl ${tw(
    ContentStyle
  )}`
)

const itemStyleDirective = css(
  { all: 'unset' },
  apply`flex items-center relative h-6 pl-6 pr-2 select-none py-1 border-2 rounded-lg text-sm cursor-pointer`,
  {
    '&[data-disabled]': {
      color: colors.gray[500],
      pointerEvents: 'none'
    },

    '&:focus': {
      backgroundColor: colors.indigo[800],
      color: colors.indigo[50]
    }
  }
)

const itemStyleClass = tw(itemStyleDirective)

const StyledItem = styled(DropdownMenuPrimitive.Item, itemStyleClass)
const StyledCheckboxItem = styled(
  DropdownMenuPrimitive.CheckboxItem,
  itemStyleClass
)
const StyledRadioItem = styled(DropdownMenuPrimitive.RadioItem, itemStyleClass)

const TriggerItemStyle = tw(
  css(itemStyleDirective, {
    '&[data-state="open"]': {
      backgroundColor: colors.indigo[800],
      color: colors.gray[50]
    },
    '&[data-state="open"] > *': {
      color: colors.gray[50]
    }
  })
)

const StyledTriggerItem = styled(
  DropdownMenuPrimitive.TriggerItem,
  `text-gray-600  focus:bg-gray-100 ${TriggerItemStyle} group`
)

const StyledLabel = styled(
  DropdownMenuPrimitive.Label,
  'pl-6 py-1 text-xs text-gray-600'
)

const StyledSeparator = styled(
  DropdownMenuPrimitive.Separator,
  'h-[1px] bg-blueGray-800 bg-opacity-20 m-1'
)

const StyledItemIndicator = styled(
  DropdownMenuPrimitive.ItemIndicator,
  'absolute left-0 w-6 inline-flex justify-center items-center'
)

const StyledArrow = styled(
  DropdownMenuPrimitive.Arrow,
  'fill-current text-blueGray-900'
)

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
export const DropdownMenuContent = StyledContent
export const DropdownMenuItem = StyledItem
export const DropdownMenuCheckboxItem = StyledCheckboxItem
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup
export const DropdownMenuRadioItem = StyledRadioItem
export const DropdownMenuItemIndicator = StyledItemIndicator
export const DropdownMenuTriggerItem = StyledTriggerItem
export const DropdownMenuLabel = StyledLabel
export const DropdownMenuSeparator = StyledSeparator
export const DropdownMenuArrow = StyledArrow

// Your app...

export const RightSlot = ({ children }: { children?: React.ReactNode }) => (
  <div className="ml-auto pl-5 text-gray-600">{children}</div>
)

export const DropdownMenuDemo = () => {
  const [bookmarksChecked, setBookmarksChecked] = React.useState(true)
  const [urlsChecked, setUrlsChecked] = React.useState(false)
  const [person, setPerson] = React.useState('pedro')

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <HamburgerMenuIcon />
        </DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          <DropdownMenuItem>New Tab</DropdownMenuItem>
          <DropdownMenuItem>New Window</DropdownMenuItem>
          <DropdownMenuItem disabled>New Private Window</DropdownMenuItem>
          <DropdownMenu>
            <DropdownMenuTriggerItem>
              More Tools
              <RightSlot>
                <ChevronRightIcon />
              </RightSlot>
            </DropdownMenuTriggerItem>
            <DropdownMenuContent sideOffset={2} alignOffset={-5}>
              <DropdownMenuItem>Save Page As…</DropdownMenuItem>
              <DropdownMenuItem>Create Shortcut…</DropdownMenuItem>
              <DropdownMenuItem>Name Window…</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem>Developer Tools</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <DropdownMenuSeparator />
          <DropdownMenuCheckboxItem
            checked={bookmarksChecked}
            onCheckedChange={setBookmarksChecked}>
            <DropdownMenuItemIndicator>
              <CheckIcon />
            </DropdownMenuItemIndicator>
            Show Bookmarks
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={urlsChecked}
            onCheckedChange={setUrlsChecked}>
            <DropdownMenuItemIndicator>
              <CheckIcon />
            </DropdownMenuItemIndicator>
            Show Full URLs
          </DropdownMenuCheckboxItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>People</DropdownMenuLabel>
          <DropdownMenuRadioGroup value={person} onValueChange={setPerson}>
            <DropdownMenuRadioItem value="pedro">
              <DropdownMenuItemIndicator>
                <DotFilledIcon />
              </DropdownMenuItemIndicator>
              Pedro Duarte
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="colm">
              <DropdownMenuItemIndicator>
                <DotFilledIcon />
              </DropdownMenuItemIndicator>
              Colm Tuite
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
          <DropdownMenuArrow />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default DropdownMenuDemo
